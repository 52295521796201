/**
 * Solicitudes Pragma SA
 */

import React, { useContext, useState } from 'react';

import { formatISO, getDay } from 'date-fns';
import { useFormContext, Controller } from 'react-hook-form';

import SelectApprover from '@/components/selects/selectApprover';
import SelectCity from '@/components/selects/selectCity';
import SelectDocType from '@/components/selects/selectDocType';
import calendar from '@/components/ui/calendar';
import TextField from '@/components/ui/textField';
import { sessionContext } from '@/contexts/session';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Apps } from '@/pages';
import { Types, ROL } from '@/services/catalogs';

import { TForm } from '..';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const Info = () => {
  const t = useLocalTranslation(i18n as Resource);
  const {
    setValue,
    clearErrors,
    control,
    watch,
    formState: { errors },
  } = useFormContext<TForm>();
  const { state } = useContext(sessionContext); 
  const [dateConsignment, setDateConsignment] = useState<Date | null>();
  const [city, setCity] = useState<Types.TCity>();

  return (
    <section className={styles['info']}>
      <h3>{t('title')}</h3>
      <form>
        <SelectDocType className={styles['type']} value={state.data?.idTipoDocumento} isDisabled />
        <TextField
          label={t('document.label')}
          className={styles['document']}
          value={state.data?.identificacion}
          readOnly
          read
        />
        <Controller
          control={control}
          name="ciudadResidencia"
          rules={{ validate: (value) => !!value || t('formValidation.required') }}
          render={() => (
            <SelectCity
              label={t('city.label')}
              className={styles['city']}
              error={errors['ciudadResidencia']?.message}
              onChange={(value) => {
                if (value) {
                  clearErrors('ciudadResidencia');
                  setValue('ciudadResidencia', value.ciudadId);
                  setCity(value);
                }
              }}
              value={city}
            />
          )}
        />
        <Controller
          control={control}
          name="fechaConsignacion"
          rules={{ validate: (value) => !!value || t('formValidation.required') }}
          render={({ field: { ref, name } }) => (
            <calendar.Input
              labelRef={ref}
              name={name}
              label={t('consignmentDate.label')}
              placeholderText={t('consignmentDate.placeholder')}
              className={styles['date-consignment']}
              selected={dateConsignment}
              minDate={new Date()}
              filterDate={(date) => {
                const day = getDay(date);
                return day === 5;
              }}
              error={errors['fechaConsignacion']?.message}
              onChange={(value) => {
                if (value) {
                  setDateConsignment(value);
                  clearErrors('fechaConsignacion');
                  setValue('fechaConsignacion', formatISO(value, { representation: 'date' }));
                }
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="idUsuarioAutorizador"
          rules={{ validate: (value) => !!value || t('formValidation.required') }}
          render={() => (
            <SelectApprover
              className={styles['approver']}
              app={Apps.ADVANCES}
              rol={ROL.advancesApprover}
              userId={state.data?.id}
              onChange={(value) => {
                if (value) {
                  clearErrors('idUsuarioAutorizador');
                  setValue('idUsuarioAutorizador', value);
                  setValue('idUsuario', state.data?.id || '');
                }
              }}
              value={watch('idUsuarioAutorizador')}
              error={errors['idUsuarioAutorizador']?.message}
            />
          )}
        />
      </form>
    </section>
  );
};

export default Info;
