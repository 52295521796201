import React, { useContext, useEffect, useState } from 'react';

import Toggle from '@/components/ui/toggle';
import { utilsContext } from '@/contexts/utils';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { getEnablePoll, toggleEnablePoll } from '@/services/advances';

import i18n from './i18n.json';
import styles from './styles.module.scss';

enum Params {
  ENABLE_POLL = 2,
}

const PollPage = () => {
  const t = useLocalTranslation(i18n as Resource);
  const { actions } = useContext(utilsContext);
  const [enablePoll, setEnablePoll] = useState<boolean>();

  useEffect(() => {
    actions.setLoading(true);
    getEnablePoll(Params.ENABLE_POLL)
      .finally(() => actions.setLoading(false))
      .then((res) => setEnablePoll(res?.estadoBandera))
      .catch((err) => actions.addToast(err.message, 'danger'));
  }, []);

  return (
    <section className={styles['options']}>
      <Toggle
        data-testid="poll-page_question_toogle"
        label={t('question')}
        disabled={enablePoll === undefined}
        checked={enablePoll}
        onChange={() => {
          actions.setLoading(true);
          toggleEnablePoll(Params.ENABLE_POLL)
            .finally(() => actions.setLoading(false))
            .then((res) => setEnablePoll(res?.estadoBandera))
            .catch((err) => actions.addToast(err.message, 'danger'));
        }}
      />
    </section>
  );
};

export default PollPage;
